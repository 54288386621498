import React from "react";
import {
  Modal,
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";

const StudentInfoModal = ({ open, onClose, currentParticipant }) => {
  const getFlagEmoji = (countryCode) =>
    typeof String.fromCodePoint !== 'undefined'
      ? countryCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          )
      : null;  
   
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "30%", // Adjust for positioning to avoid overlap
          transform: "translate(-50%, -50%)",
          width: "35%",
          bgcolor: "background.paper", // Consistent color
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          maxHeight: '100%',
          zIndex:300
        }}
      >
        <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
          Student Information
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Personal Details</Typography>
                <Typography>First Name: {currentParticipant?.f_name}</Typography>
                <Typography>Last Name: {currentParticipant?.l_name}</Typography>
                <Typography>Email: {currentParticipant?.email}</Typography>
                <Typography>Mobile: {currentParticipant?.mobile}</Typography>
                <Typography>Birth Date: {currentParticipant?.birthdate}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">Study Preferences</Typography>
                <Typography>
                  Preferred Study Level: {currentParticipant?.preferredStudyLevel}
                </Typography>
                <Typography>English Level: {currentParticipant?.englishLevel}</Typography>
                <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.highestEducationLevel ? 'black' : 'red' }}>
    <strong>Highest Education Level:</strong> {currentParticipant?.highestEducationLevel || 'N/A'}
  </Typography>
                <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.preferredDestinations?.length ? 'black' : 'red' }}>
    <strong>Desired Destinations:</strong>
    {Array.isArray(currentParticipant?.preferredDestinations) && currentParticipant.preferredDestinations.length
      ? currentParticipant.preferredDestinations.map((destination) => 
        `${destination?.code ? getFlagEmoji(destination.code) + ' ' : ''}${destination?.label || 'N/A'}`
        ).join(', ')
      : 'N/A'}
  </Typography>
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.studyFields?.length ? 'black' : 'red' }}>
    <strong>Desired Fields:</strong> {currentParticipant?.studyFields?.length ? currentParticipant.studyFields.map(field => field.label).join(', ') : 'N/A'}
  </Typography>
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.budget ? 'black' : 'red' }}>
    <strong>Budget:</strong> {currentParticipant?.budget ? `$${currentParticipant.budget}` : 'N/A'}
  </Typography>
  <Typography variant="body1" sx={{ mb: 1, color: currentParticipant?.dateTime ? 'black' : 'red' }}>
    <strong>Application Creation Date:</strong> {currentParticipant?.dateTime ? `${currentParticipant.dateTime}` : 'N/A'}
  </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default StudentInfoModal;
