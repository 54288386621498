import React, { useState } from 'react';
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Divider,
  TextField,
  Tooltip,
} from '@mui/material';
import { Close, Comment, Send, Edit, Delete } from '@mui/icons-material';

const CommentModal = ({
  open,
  onClose,
  currentParticipant,
  newComment,
  setNewComment,
  handleCommentSubmit,
  handleCommentEdit,
}) => {
  const [editComment, setEditComment] = useState(null);

  // Handle comment click for editing
  const handleEditClick = (comment) => {
    setEditComment(comment); // Set the comment to edit
    setNewComment(comment.text); // Populate TextField with the comment text
  };



  return (
    <Modal open={open} BackdropProps={{ invisible: true }} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '70%', // Adjust for positioning
          transform: 'translate(-50%, -50%)',
          width: '40%',
          bgcolor: 'background.paper', // Consistent color
          borderRadius: 3,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          maxHeight: '85%',
          zIndex: 300,
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onClose}
          sx={{ position: 'absolute', top: 16, right: 16, color: 'grey.600' }}
        >
          <Close />
        </IconButton>

        {/* Title with Comment Icon */}
        <Box display="flex" alignItems="center" mb={3}>
          <Comment sx={{ color: 'primary.main', fontSize: 28, mr: 1 }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
            Notes
          </Typography>
        </Box>

        <Divider sx={{ mb: 3 }} />

        {/* Display Existing Comments */}
        {currentParticipant?.comment?.length > 0 ? (
          currentParticipant.comment
            .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)) // Order by date
            .map((comment, index) => (
              <Box
                key={index}
                sx={{
                  mb: 2,
                  p: 2,
                  border: '1px solid #eee',
                  borderRadius: 2,
                  backgroundColor: '#f9f9f9',
                  wordWrap: 'break-word',
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'text.primary' }}>
                  {comment.text}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1 }}>
                  {comment.dateTime}
                </Typography>

                {/* Edit and Delete Buttons */}
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                  <Tooltip title="Edit Comment">
                    <IconButton
                      color="primary"
                      onClick={() => handleEditClick(comment)}
                      sx={{ mr: 1 }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ))
        ) : (
          <Typography variant="body1" sx={{ color: 'text.secondary', mb: 2 }}>
            No Notes yet.
          </Typography>
        )}

        <Divider sx={{ my: 3 }} />

        {/* Input Field for New Comment */}
        <TextField
          label={editComment ? "Edit Comment" : "Add a Note"}
          value={newComment}
          multiline
          rows={4}
          onChange={(e) => setNewComment(e.target.value)}
          fullWidth
          required
          margin="normal"
          variant="outlined"
          InputProps={{
            sx: { borderRadius: 2, maxWidth: '100%', width: '100%' },
            endAdornment: (
              <IconButton
                disabled={newComment.trim() === ''} // Disable if newComment is empty
                onClick={() => {
                  if (editComment) {
                    handleCommentEdit(editComment); // Edit the comment
                  } else {
                    handleCommentSubmit(); // Submit new comment
                  }
                }}
                color="primary"
              >
                <Send />
              </IconButton>
            ),
          }}
          InputLabelProps={{ sx: { fontWeight: 'bold' } }}
        />
      </Box>
    </Modal>
  );
};

export default CommentModal;
