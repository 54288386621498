import React from 'react';
import { Modal, Box, Typography, List, ListItem, ListItemText } from '@mui/material';

const ParticipantLogsModal = ({ openLogs, handleCloseLogs, currentParticipant }) => {
  return (
    currentParticipant && (
      <Modal open={openLogs} onClose={handleCloseLogs}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            borderRadius: '16px',
            boxShadow: '0 8px 32px rgba(0,0,0,0.2)',
            p: 4,
            overflowY: 'auto',
            maxHeight: '85%',
            outline: 'none',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#bbb',
              borderRadius: '8px',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: '#888',
            },
            '&:before': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '4px',
              bgcolor: 'primary.main',
              borderRadius: '16px 16px 0 0',
            },
          }}
        >
          {/* Modal Title */}
          <Typography
            variant="h5"
            component="h2"
            gutterBottom
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              mb: 3,
              color: 'primary.main',
              position: 'relative',
              '&:after': {
                content: '""',
                width: '50px',
                height: '4px',
                backgroundColor: 'primary.main',
                position: 'absolute',
                bottom: '-8px',
                left: '50%',
                transform: 'translateX(-50%)',
                borderRadius: '2px',
              },
            }}
          >
            Logs
          </Typography>

          {/* Log List */}
          <List
            sx={{
              bgcolor: '#f9f9f9',
              borderRadius: '12px',
              boxShadow: '0 2px 12px rgba(0,0,0,0.05)',
              padding: '16px',
            }}
          >
            {currentParticipant.logs.slice().reverse().map((log, index) => (
              <ListItem
                key={index}
                sx={{
                  mb: 1.5,
                  p: 2,
                  borderRadius: '12px',
                  backgroundColor: index % 2 === 0 ? '#fafafa' : '#ffffff', // Alternating background colors
                  transition: 'background 0.3s, transform 0.2s',
                  '&:hover': {
                    bgcolor: 'grey.100',
                    transform: 'scale(1.02)',
                  },
                }}
              >
                <ListItemText
                  primaryTypographyProps={{
                    fontSize: '1rem',
                    fontWeight: '500',
                    color: 'text.primary',
                  }}
                  secondaryTypographyProps={{
                    fontSize: '0.875rem',
                    color: 'text.secondary',
                  }}
                  primary={
                    <>
                      <span
                        style={{
                          marginRight: '8px',
                          fontWeight: 'bold',
                          color: 'secondary.main',
                        }}
                      >
                        <i className="material-icons">assignment</i>
                      </span>
                      {typeof log.field === 'object' ? JSON.stringify(log.field) : log.field}
                    </>
                  }
                  secondary={
                    <>
                      <span>
                        <strong>Old Value:</strong>{' '}
                        {typeof log.oldValue === 'object' ? JSON.stringify(log.oldValue) : log.oldValue}
                      </span>{' '}
                      <br />
                      <span>
                        <strong>New Value:</strong>{' '}
                        {typeof log.newValue === 'object' ? JSON.stringify(log.newValue) : log.newValue}
                      </span>{' '}
                      <br />
                      <span style={{ fontStyle: 'italic', color: '#777' }}>
                        {new Date(log.date).toLocaleString()}
                      </span>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Modal>
    )
  );
};

export default ParticipantLogsModal;
