import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Collapse,IconButton,FormControl, InputLabel, MenuItem, Button, Grid, CssBaseline } from '@mui/material';
import { styled } from '@mui/system';
import Select from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import "../RegistrationForm.css"
import axios from 'axios';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { de, enGB, zhCN } from 'date-fns/locale';
import dayjs from 'dayjs';
import LinearProgress from '@mui/material/LinearProgress';
import CloseIcon from '@mui/icons-material/Close';
import EmailModalEventFind from '../components/EmailModalEventFind'; // Adjust the path if necessary
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';
import TermsAndConditions from "./terms"

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },secondary:{
      main: "#000000"
    }
  }
});

const StyledForm = styled('form')({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '90vw', // Set the width as 90% of the viewport width
  margin: '0 auto',
  background: 'rgba(255, 255, 255, 0.9)', // Semi-transparent background
  padding: '20px', // Add padding for better readability
  borderRadius: '10px', // Rounded corners
});
const StyledTitle = styled(Typography)(({ theme }) => ({
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 'bold',
  fontSize: '2.5rem', // Adjust size as needed
  color: 'transparent',
  background: 'linear-gradient(135deg, #C8102E 0%, #000000 100%)', // Gradient background
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  letterSpacing: '0.05em',
  textTransform: 'uppercase',
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%',
    height: '2px',
    backgroundColor: '#FF6347', // Accent color for underline
    transform: 'scaleX(0)',
    transformOrigin: 'bottom right',
    transition: 'transform 0.3s ease-out',
  },
  '&:hover::after': {
    transform: 'scaleX(1)',
    transformOrigin: 'bottom left',
  },
}));
const BackgroundContainer = styled(Container)({
  background: 'url("https://edugate-eg.com/wp-content/uploads/2023/08/8O0A0068_result-e1712786425854.webp")', // Replace with your background image URL
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
});

const EdugateForm = () => {

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };


  const [progress, setprogress] = useState(false);
  const [countries, setCountries] = useState([]);
  const [governoratesInEgypt, setGovernoratesInEgypt] = useState([]);
  const [desiredFields, setDesiredFields] = useState([]);
  const [postgraduate_looking_for, setLookingFor] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
//
const [alertOpen, setAlertOpen] = React.useState(true);
const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);
const [Accepted, setAccepted] = useState(true);


const handleAccept = (isAccepted) => {

  setAccepted(!isAccepted)
}
const handleOpenEmailModal = () => {
  setIsEmailModalOpen(true);
};

const handleCloseEmailModal = () => {
  setIsEmailModalOpen(false);
};
  var currentdate = new Date(); 
var datetime = currentdate.getDate() + "/"
                + (currentdate.getMonth()+1)  + "/" 
                + currentdate.getFullYear() + " @ "  
                + currentdate.getHours() + ":"  
                + currentdate.getMinutes() + ":" 
                + currentdate.getSeconds();

  const [formData, setFormData] = useState({
    f_name: '',
    l_name: '',
    country: "",
    gender: '',
    birth_date: "",
    email: '',
    mobile: '',
    visitor_type: '',
    hear_aboutus_at: '',
    city: '', // Add city field
    education_type: '', // New field for education type
    american_type:'',
    grade: '', // New field for grade
    is_studying_abroad: "false", // New field for studying abroad
    studyField: '', // New field for desired field
    school_name:"",
    studying_abroad_destination:"",
    postgraduate_looking_for:"",
    eventID:"18",
    date:datetime,
    budget_dollars:"",
    budget_EGP:"",
    attend:"yes"
  });

  const [availableEvents, setAvailableEvents] = useState([]);

  const fetchAvailableEvents = async () => {
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
      const filteredEvents = response.data.filter((event) => event.deleted === '0');
      setAvailableEvents(filteredEvents);
    } catch (error) {
    }
  };




  useEffect(() => {
    fetchAvailableEvents();
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setCountries(response.data[5].countries);
        setGovernoratesInEgypt(response.data[2].governoratesInEgypt);
        setDesiredFields(response.data[3].desiredFields);
        setLookingFor(response.data[4].lookingFor);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);

  useEffect(() => {
    const today = new Date();
    const activeEvent = availableEvents.find(event => {
      const startDate = new Date(event.start_date);
      const endDate = new Date(event.end_date);
      return today >= startDate && today <= endDate;
    });

    if (activeEvent) {
      setFormData(prevState => ({
        ...prevState,
        eventID: activeEvent.id
      }));
    }
  }, [availableEvents]);


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    let newValue = value;
  
    // Capitalize the first letter for first name and last name
    if (name === 'f_name' || name === 'l_name') {
      newValue = capitalizeFirstLetter(value);
    }
  
    // Handle visitor_type changes and reset relevant fields
    if (name === 'visitor_type') {
      let resetFields = {
        budget_dollars: "",
        studying_abroad_destination: "",
        studyField: "",
        postgraduate_looking_for: "",
        is_studying_abroad: false,
        education_type: "",
        grade: "",
        budget_EGP: "",
        school_name: "",
        american_type: "", // Default reset american_type
      };
  
      if (value === 'postGraduate') {
        setFormData({
          ...formData,
          [name]: newValue,
          ...resetFields
        });
      } else if (value === 'underGraduate') {
        // Check if the education_type is 'american' and preserve american_type
        const updatedFields = formData.education_type === 'american'
          ? { ...resetFields, american_type: formData.american_type }
          : resetFields;
  
        setFormData({
          ...formData,
          [name]: newValue,
          ...updatedFields,
          is_studying_abroad: false, // Explicitly set to false
        });
      } else if (value === 'parent') {
        setFormData({
          ...formData,
          [name]: newValue,
          ...resetFields,
          is_studying_abroad: false, // Explicitly set to false
        });
      }
    } else if (name === 'education_type') {
      // Handle changes in education_type
      const updatedFields = (formData.visitor_type === 'underGraduate' && value === 'american')
        ? { american_type: formData.american_type } // Preserve american_type
        : { american_type: "" }; // Reset american_type otherwise
  
      setFormData({
        ...formData,
        [name]: newValue,
        ...updatedFields
      });
    } else if (name === 'budget_EGP') {
      setFormData({
        ...formData,
        [name]: newValue,
        budget_dollars: "", // Reset budget_dollars
        studying_abroad_destination: "" // Optionally reset destination
      });
    } else if (name === 'budget_dollars') {
      setFormData({
        ...formData,
        [name]: newValue,
        budget_EGP: "", // Reset budget_EGP
      });
    } else if (type === 'checkbox') {
      setFormData({
        ...formData,
        [name]: checked, // Update with the checkbox's checked state
      });
    } else {
      setFormData({
        ...formData,
        [name]: newValue, // Update other fields with their values
      });
    }
  };
  
  
  
  
  const [feedback, setFeedback] = useState({
    message: '',
    type: '', // 'success' or 'error'
  });

  const [formStates, setFormStates] = useState(null);
  useEffect(() => {
    fetchFormStates();
  }, []);
  
  
  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data.Edugate.Event_Registration);
      setFormData(
        {
          f_name: '',
          l_name: '',
          country: "",
          gender: '',
          birth_date: "",
          email: '',
          mobile: '',
          visitor_type: '',
          hear_aboutus_at: '',
          city: '', // Add city field
          education_type: '', // New field for education type
          american_type:'',
          grade: '', // New field for grade
          is_studying_abroad: "false", // New field for studying abroad
          studyField: '', // New field for desired field
          school_name:"",
          studying_abroad_destination:"",
          postgraduate_looking_for:"",
          eventID:response.data.registerEvent,
          date:datetime,
          budget_dollars:"",
          budget_EGP:"",
          attend:"yes" 
        }
      )
    } catch (error) {
      console.error('Error fetching form states', error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();


    if (!formData.birth_date) {
      setFeedback({
        message: "Please fill in all the fields",
        type: 'error',
      });
      return;
    }
    setIsSubmitting(true);


    setprogress(true);

    
    try {

      const formattedDate = dayjs(formData.birth_date).format('DD/MM/YYYY');
  
      // Update the formData with the formatted birth_date
const formData2={
        ...formData,
        birth_date: formattedDate,
      };
  
      const response = await fetch('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/registrationsEvent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData2),
      });
  
      if (response.status === 201) {
        setFeedback({
          message: "Thank You for Registering",
          type: 'success',
        });
      } else if(response.status === 400){
        setIsSubmitting(false);
        setFeedback({
          message: "You have already regsitered with this email before",
          type: 'error',
        });

      }else {
        // Handle errors from the backend
        setFeedback({
          message: "The server is busy handling other requests. Please Refresh the page and try again after 5 mins.",
          type: 'error',
        });
      }
    } catch (error) {
      // Handle network errors
      setFeedback({
        message: error,
        type: 'error',
      });
    }
    setprogress(false);


  };

  return (
       <div>

{formStates && (
        <div style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 9999,
          width: '400px'
        }}>
          <Alert 
            icon={<CheckIcon fontSize="inherit" />} 
            severity="error"
            style={{ display: 'flex', alignItems: 'center' }}
          >
Registration is Currently Closed.          
</Alert>
        </div>
      )}

<div style={formStates ? { filter: 'blur(5px)', pointerEvents: 'none' } : {}}>

    <BackgroundContainer component="main" maxWidth="">


    <Box
          sx={{
            marginTop:5,
            marginBottom: 3,
            padding: 4,
            backgroundColor: '#f5f5f5', // Light grey background for contrast
            borderRadius: 4, // Slightly more rounded corners
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Softer shadow for a more modern look
          }}
        >
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item>
              <Button
               startIcon={<SearchIcon />}
                variant="contained"
                color="secondary"
                onClick={handleOpenEmailModal}
                sx={{
                  fontWeight: 'bold',
                  padding: '10px 20px', // Increased padding for a larger button
                  fontSize: '1.2rem', // Larger font size for better visibility
                  textTransform: 'none', // Disable uppercase transformation for a cleaner look
                  bgcolor: '#000', // Set the background color to black
    color: '#fff', // Set the text color to white for contrast
    '&:hover': {
      bgcolor: '#333', // Slightly lighter black for the hover state
    },
                  borderRadius: 3, // Slightly increased border radius for a smoother look
                  boxShadow: '0px 5px 15px rgba(33, 150, 243, 0.4)', // More prominent shadow with a blue tint
                }}
              >
                Find via Email
              </Button>
            </Grid>
          </Grid>
        </Box>

        <EmailModalEventFind
          open={isEmailModalOpen}
          handleClose={handleCloseEmailModal}
          eventId={formData.eventID} // Pass the event ID dynamically
        />
<div style={{marginTop:"50px", width:"90vw"}} className="form-menu-container">

        <ThemeProvider theme={theme}>
  <CssBaseline />
  <br/>
  <br/>
  <StyledForm onSubmit={handleSubmit}>
  <Collapse in={alertOpen}>
      <Alert
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={() => {
            setAlertOpen(false);
          }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
       variant="outlined" severity="warning">
      Please note that registering with the same email address multiple times is not permitted.
    </Alert>
    </Collapse>
  <LocalizationProvider dateAdapter={AdapterDateFns}  adapterLocale={enGB}>
  <Box sx={{ textAlign: 'center', mt: 4 }}>

<StyledTitle variant="h5" gutterBottom>
REGISTRATION FORM
</StyledTitle>
</Box>
  <Grid container spacing={2}>
  <Grid item xs={12} sm={6}>

    <TextField
    fullWidth={true}
      label="First Name"
      name="f_name"
      value={formData.f_name}
      onChange={handleChange}
      required
    />
        </Grid>
        
        <Grid item xs={12} sm={6}>

    <TextField
    fullWidth={true}
      label="Last Name"
      name="l_name"
      value={formData.l_name}
      onChange={handleChange}
      required
    />
        </Grid>

        </Grid>

    <Autocomplete
id="country-select-demo"
fullWidth
options={countries}
autoHighlight
getOptionLabel={(option) => option.label}
value={countries.find((c) => c.label === formData.country) || null}
onChange={(event, newValue) => {
setFormData({
  ...formData,
  country: newValue ? newValue.label : null, // Set to null if no selection
});
}}
inputValue={formData.country || ''}
onInputChange={(event, newInputValue) => {
setFormData({
  ...formData,
  country: newInputValue,
});
}}
renderOption={(props, option) => (
  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt=""
      />
      {option.label} 
    </Box>
)}
renderInput={(params) => (
<TextField
  {...params}
  label="Choose a country"
  inputProps={{
    ...params.inputProps,
    autoComplete: 'new-password',
  }}
  name="country"
  required
/>
)}
/>


{formData.country === 'Egypt' && (
<Autocomplete
id="city-select-demo"
required
fullWidth
options={governoratesInEgypt}
autoHighlight
getOptionLabel={(option) => option.label}
value={governoratesInEgypt.find((g) => g.label === formData.city) || null}
onChange={(event, newValue) => {
  setFormData({
    ...formData,
    city: newValue ? newValue.label : '', // Set the city label directly
  });
}}
inputValue={formData.city || ''}
onInputChange={(event, newInputValue) => {
  setFormData({
    ...formData,
    city: newInputValue,
  });
}}
renderInput={(params) => (
  <TextField
    {...params}
    label="Choose a city"
    inputProps={{
      ...params.inputProps,
      autoComplete: 'new-password',
    }}
    name="city"
    required
  />
)}
/>
)}
                <FormControl fullWidth required>
      <InputLabel>Gender</InputLabel>
      <Select
        name="gender"
        value={formData.gender}
        onChange={handleChange}
        required
        label="Gender"

      >
        <MenuItem value="male">Male</MenuItem>
        <MenuItem value="female">Female</MenuItem>
      </Select>
    
    </FormControl>
    <Grid container spacing={2}>
  <Grid item xs={12} sm={4}>
  <DesktopDatePicker
      required
      label="Birth Date"
      inputFormat="MM/dd/yyyy"
      value={formData.birth_date}
      onChange={(newDate) => setFormData({ ...formData, birth_date: newDate })}
      renderInput={(params) => <TextField {...params} fullWidth required />}
      fullWidth={true}
      slotProps={{
        textField: {
          error: false,
        },
      }}
      sx={{width: '100%'}}
      textFieldStyle={{width: '100%'}}
      openTo="year"
      views={['year', 'month', 'day']}
      minDate={new Date("1930-01-01")}
      maxDate={new Date()} // Set maximum date as current date
    />
      </Grid>
    <Grid item xs={12} sm={4}>


    <TextField
            fullWidth={true}

      label="Email"
      name="email"
      type="email"
      value={formData.email}
      onChange={handleChange}
      required
    />
              </Grid>
              <Grid item xs={12} sm={4}>

    <TextField
    fullWidth={true}
      label="Mobile"
      name="mobile"
      type="tel"
      value={formData.mobile}
      onChange={handleChange}
      required
      inputProps={{ pattern: '[0-9]*' }} 

    />
                      </Grid>
                      </Grid>

                      <FormControl fullWidth required>
      <InputLabel>Visitor Type</InputLabel>
      <Select
        name="visitor_type"
        value={formData.visitor_type}
        onChange={handleChange}
        required
        label="Visitor Type"


      >
        <MenuItem value="parent">Parent</MenuItem>
        <MenuItem value="postGraduate">PostGraduate</MenuItem>
        <MenuItem value="underGraduate">UnderGraduate</MenuItem>
      </Select>
    </FormControl>
    {formData.visitor_type === 'underGraduate' && (
  <>
  
  <FormControl fullWidth required>
      <InputLabel>Education Type</InputLabel>
      <Select
        name="education_type"
        value={formData.education_type}
        onChange={handleChange}
        required
        label="Education Type"
      >
        <MenuItem value="American Diploma">American Diploma</MenuItem>
        <MenuItem value="IGCSE Diploma">IGCSE Diploma</MenuItem>
        <MenuItem value="Thanaweya Amma">Thanaweya Amma</MenuItem>
        <MenuItem value="Canadian Diploma">Canadian Diploma</MenuItem>
        <MenuItem value="International Baccalaureate Diploma">International Baccalaureate Diploma</MenuItem>
        <MenuItem value="French Baccalaureate Diploma">French Baccalaureate Diploma</MenuItem>
        <MenuItem value="German Abitur">German Abitur</MenuItem>
        <MenuItem value="BTEC">BTEC</MenuItem>
        <MenuItem value="WAEC">WAEC</MenuItem>
        <MenuItem value="Nile Certificarte (Cambridge)">Nile Certificarte (Cambridge)</MenuItem>
        <MenuItem value="International Education System">International Education System</MenuItem>
        <MenuItem value="Foreign Certificate">Foreign Certificate</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
      </Select>
    </FormControl>
    {formData.education_type === 'American Diploma' && (
          <FormControl fullWidth required>
          <InputLabel>Which Test?</InputLabel>
          <Select
            name="american_type"
            value={formData.american_type}
            onChange={handleChange}
            required
            label="Which Test?"
          >
            <MenuItem value="EST">EST</MenuItem>
            <MenuItem value="ACT">ACT</MenuItem>
            <MenuItem value="Digital SAT">Digital SAT</MenuItem>
          </Select>
        </FormControl>



        )}
    
          <FormControl>

  <TextField
      label="School Name"
      name="school_name"
      value={formData.school_name}
      onChange={handleChange}
      required
    />
     </FormControl>


     <FormControl fullWidth required>
      <InputLabel>Grade</InputLabel>
      <Select
        name="grade"
        value={formData.grade}
        onChange={handleChange}
        required
        label="Grade"
      >
        <MenuItem value="Grade 10">Grade 10</MenuItem>
        <MenuItem value="Grade 11">Grade 11</MenuItem>
        <MenuItem value="Grade 12">Grade 12</MenuItem>

      </Select>
    </FormControl>

    <FormControl>
    <FormControlLabel
    control={
      <Checkbox
        name="is_studying_abroad"
        onChange={handleChange}
        color="primary"
      />
    }
    label="Are you willing to study abroad?"
  />
  </FormControl>
  {formData.is_studying_abroad=== true  && (
  <div>
    <FormControl fullWidth required>
<InputLabel>What is your Budget Range in USD?</InputLabel>
<Select
name="budget_dollars"
value={formData.budget_dollars}
onChange={handleChange}
required
label="What is your Budget Range in USD?"
>
<MenuItem value="<2000 USD">{"< $2,000"} </MenuItem>
<MenuItem value="2000 USD - 10000 USD">$2,000 - $10,000</MenuItem>
<MenuItem value="10000 USD - 20000 USD">$10,000 - $20,000</MenuItem>
<MenuItem value=">20000 USD">{"> $20,000"}</MenuItem>
</Select>
</FormControl>

   <Autocomplete
   fullWidth
   options={countries}
   autoHighlight
   getOptionLabel={(option) => option.label}
   value={countries.find((c) => c.label === formData.studying_abroad_destination) || null}
   onChange={(event, newValue) => {
     setFormData({
       ...formData,
       studying_abroad_destination: newValue ? newValue.label : null, // Set to null if no selection
     });
   }}
   inputValue={formData.studying_abroad_destination || ''}
   onInputChange={(event, newInputValue) => {
     setFormData({
       ...formData,
       studying_abroad_destination: newInputValue,
     });
   }}
   renderOption={(props, option) => (
       <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt=""
      />
      {option.label} 
    </Box>                
   )}
   renderInput={(params) => (
     <TextField
       {...params}
       label="Choose a country"
       inputProps={{
         ...params.inputProps,
         autoComplete: 'new-password',
       }}
       name="country"
       required
       style={{ marginTop: '16px' }} 
     />
   )}
 />

</div>
  )}

<div>
  {(formData.is_studying_abroad=== "false" || formData.is_studying_abroad=== false)  && (
      <FormControl fullWidth required>
      <InputLabel>What is your Budget Range in EGP?</InputLabel>
      <Select
        name="budget_EGP"
        value={formData.budget_EGP}
        onChange={handleChange}
        required
        label="What is your Budget Range in EGP?"
        style={{ marginBottom: '16px' }} 
      >
        <MenuItem value="<100000 EGP">{"< EGP 100,000"} </MenuItem>
        <MenuItem value="100000 EGP - 200000 EGP">EGP 100,000 - EGP 200,000</MenuItem>
        <MenuItem value="200000 EGP - 300000 EGP">EGP 200,000 - EGP 300,000</MenuItem>
        <MenuItem value=">300000 EGP">{"> EGP 300,000"}</MenuItem>
      </Select>
    </FormControl>
    
    )}
    <Autocomplete
      id="desired-field-select"
      fullWidth
      options={desiredFields} // Define your desired fields array
      autoHighlight
      getOptionLabel={(option) => option.label}
      value={desiredFields.find((field) => field.label === formData.studyField) || null}
      onChange={(event, newValue) => {
        setFormData({
          ...formData,
          studyField: newValue ? newValue.label : null,
        });
      }}
      inputValue={formData.studyField || ''}
      onInputChange={(event, newInputValue) => {
        setFormData({
          ...formData,
          studyField: newInputValue,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Desired Field"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          name="studyField"
          required
          //style={{ marginTop: '16px' }} 
        />
      )}
    />
</div>
  </>
)}
{formData.visitor_type === 'postGraduate' && (
  <>
  <div>
       <Autocomplete
      id="looking-for-select"
      fullWidth
      options={postgraduate_looking_for} // Define your desired fields array
      autoHighlight
      getOptionLabel={(option) => option.label}
      onChange={(event, newValue) => {
        setFormData({
          ...formData,
          postgraduate_looking_for: newValue ? newValue.label : null,
        });
      }}
      inputValue={formData.postgraduate_looking_for || ''}
      onInputChange={(event, newInputValue) => {
        setFormData({
          ...formData,
          postgraduate_looking_for: newInputValue,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Looking For?"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          name="postgraduate_looking_for"
          required
        />
      )}
    />   
    <FormControl>
    <FormControlLabel
    control={
      <Checkbox
        name="is_studying_abroad"
        onChange={handleChange}
        color="primary"
      />
    }
    label="Are you willing to study abroad?"
  />
  </FormControl>
  {formData.is_studying_abroad=== true  && (
    <div>
    <FormControl fullWidth required>
<InputLabel>What is your Budget Range in USD?</InputLabel>
<Select
name="budget_dollars"
value={formData.budget_dollars}
onChange={handleChange}
required
label="What is your Budget Range in USD?"
>
<MenuItem value="<2000 USD">{"< $2,000"} </MenuItem>
<MenuItem value="2000 USD - 10000 USD">$2,000 - $10,000</MenuItem>
<MenuItem value="10000 USD - 20000 USD">$10,000 - $20,000</MenuItem>
<MenuItem value=">20000 USD">{"> $20,000"}</MenuItem>
</Select>
</FormControl>

   <Autocomplete
   fullWidth
   options={countries}
   autoHighlight
   getOptionLabel={(option) => option.label}
   value={countries.find((c) => c.label === formData.studying_abroad_destination) || null}
   onChange={(event, newValue) => {
     setFormData({
       ...formData,
       studying_abroad_destination: newValue ? newValue.label : null, // Set to null if no selection
     });
   }}
   inputValue={formData.studying_abroad_destination || ''}
   onInputChange={(event, newInputValue) => {
     setFormData({
       ...formData,
       studying_abroad_destination: newInputValue,
     });
   }}
   renderOption={(props, option) => (
       <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
      <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
        alt=""
      />
      {option.label} 
    </Box>                
   )}
   renderInput={(params) => (
     <TextField
       {...params}
       label="Choose a country"
       inputProps={{
         ...params.inputProps,
         autoComplete: 'new-password',
       }}
       name="country"
       required
       style={{ marginTop: '16px' }} 
     />
   )}
 />
 </div>
  )}
    </div>
    <div>
  {(formData.is_studying_abroad=== "false" || formData.is_studying_abroad=== false)  && (
      <FormControl fullWidth required>
      <InputLabel>What is your Budget Range in EGP?</InputLabel>
      <Select
        name="budget_EGP"
        value={formData.budget_EGP}
        onChange={handleChange}
        required
        label="What is your Budget Range in EGP?"
        style={{ marginBottom: '16px' }} 
      >
        <MenuItem value="<100000 EGP">{"< EGP 100,000"} </MenuItem>
        <MenuItem value="100000 EGP - 200000 EGP">EGP 100,000 - EGP 200,000</MenuItem>
        <MenuItem value="200000 EGP - 300000 EGP">EGP 200,000 - EGP 300,000</MenuItem>
        <MenuItem value=">300000 EGP">{"> EGP 300,000"}</MenuItem>
      </Select>
    </FormControl>
    
    )}


    <Autocomplete
      id="desired-field-select"
      fullWidth
      options={desiredFields} // Define your desired fields array
      autoHighlight
      getOptionLabel={(option) => option.label}
      value={desiredFields.find((field) => field.label === formData.studyField) || null}
      onChange={(event, newValue) => {
        setFormData({
          ...formData,
          studyField: newValue ? newValue.label : null,
        });
      }}
      inputValue={formData.studyField || ''}
      onInputChange={(event, newInputValue) => {
        setFormData({
          ...formData,
          studyField: newInputValue,
        });
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Desired Field"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          name="studyField"
          required
          
        />
      )}
    />
</div>
  </>
)}
    {formData.visitor_type === 'parent' && (


<FormControl fullWidth required>
      <InputLabel>What is your Budget Range in EGP?</InputLabel>
      <Select
        name="budget_EGP"
        value={formData.budget_EGP}
        onChange={handleChange}
        required
        label="What is your Budget Range in EGP?"
      >
        <MenuItem value="<100000 EGP">{"< EGP 100,000"} </MenuItem>
        <MenuItem value="100000 EGP - 200000 EGP">EGP 100,000 - EGP 200,000</MenuItem>
        <MenuItem value="200000 EGP - 300000 EGP">EGP 200,000 - EGP 300,000</MenuItem>
        <MenuItem value=">300000 EGP">{"> EGP 300,000"}</MenuItem>
      </Select>
    </FormControl>
    )}
                <FormControl fullWidth required>
      <InputLabel>How Did You Hear About Us?</InputLabel>
      <Select
        name="hear_aboutus_at"
        value={formData.hear_aboutus_at}
        onChange={handleChange}
        required
        label="How Did You Hear About Us?"
      >
        <MenuItem value="Website">Website</MenuItem>
        <MenuItem value="Friend">Friend</MenuItem>
        <MenuItem value="Google">Google Search</MenuItem>
        <MenuItem value="Facebook">Facebook</MenuItem>
        <MenuItem value="Instagram">Instagram</MenuItem>
        <MenuItem value="Linkedin">Linkedin</MenuItem>
        <MenuItem value="Tiktok">Tiktok</MenuItem>
        <MenuItem value="WhatsApp">WhatsApp</MenuItem>
        <MenuItem value="Email">Email</MenuItem>
        <MenuItem value="School">School</MenuItem>
        <MenuItem value="SMS">SMS</MenuItem>
        <MenuItem value="Radio">Radio</MenuItem>
        <MenuItem value="Outdoor">Outdoor</MenuItem>
      </Select>
    </FormControl>
    <TermsAndConditions onAccept={handleAccept} />
    {feedback.message && (
              <Typography
                variant="body1"
                style={{ color: feedback.type === 'error' ? 'red' : 'green' }}
              >
                {feedback.message}
              </Typography>
            )}
    <Button
      type="submit"
      variant="contained"
      color="primary"
      size="large"
      fullWidth
      disabled={isSubmitting || formStates || Accepted}

    >
      Submit

    </Button>
    {progress && (
<Box sx={{ width: '100%' }}>
<LinearProgress />
                </Box>
            )}
    </LocalizationProvider>

  </StyledForm>

  </ThemeProvider>
  </div>
  <br/>
  <br/>
  </BackgroundContainer>
      

      </div>
      </div>

  );
};


export default EdugateForm;



