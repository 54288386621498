import React from 'react';
import {
  Modal,
  Box,
  Typography,
  FormControl,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Grid,
  Chip,
  Autocomplete,
} from '@mui/material';
import { LocalizationProvider, DesktopDatePicker, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0033A1"
    },
    secondary: {
      main: "#0033A1"
    }
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});

const EditParticipantModal = ({
  open,
  onClose,
  currentParticipant,
  setCurrentParticipant,
  handleEditSubmit,
  handlePhotoUpload,
  photo,
  Allcountries,
  desiredFields,
  countries,
  handleDestinationsChange,
  parseDate,
}) => {

    const getFlagEmoji = (countryCode) =>
        typeof String.fromCodePoint !== 'undefined'
          ? countryCode
              .toUpperCase()
              .replace(/./g, (char) =>
                String.fromCodePoint(char.charCodeAt(0) + 127397)
              )
          : null;
  return (
    <Modal open={open} onClose={onClose}
    sx={{ zIndex: 1300 }} // Ensure the modal is above other content
>
<Box sx={{
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  borderRadius: 2,
  boxShadow: 24,
  p: 4,
  overflowY: 'auto',
  maxHeight: '85%',
}}>
  <ThemeProvider theme={theme}>

<Typography
id="modal-title"
variant="h5"
component="h2"
sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
>Edit Participant</Typography>
  <LocalizationProvider dateAdapter={AdapterDateFns}>

<form onSubmit={handleEditSubmit}>
<FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="First Name"
    value={currentParticipant?.f_name || ''}

    onChange={(e) => setCurrentParticipant({ ...currentParticipant, f_name: e.target.value })}
    fullWidth
    required
  />
</FormControl><FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="Last Name"
    value={currentParticipant?.l_name || ''}

    onChange={(e) => setCurrentParticipant({ ...currentParticipant, l_name: e.target.value })}
    fullWidth
    required
  />
</FormControl>

<FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="Mobile"
    value={currentParticipant?.mobile || ''}

    onChange={(e) => setCurrentParticipant({ ...currentParticipant, mobile: e.target.value })}
    fullWidth
    required
  />
</FormControl>
<FormControl fullWidth margin="normal" variant="outlined">

<DesktopDatePicker
required
label="Birth Date"
value={currentParticipant?.birthdate ? parseDate(currentParticipant.birthdate) : null} // Ensure null for invalid inputs
onChange={(newDate) =>
setCurrentParticipant((prev) => ({
...prev,
birthdate: newDate,
}))
}
renderInput={(params) => <TextField {...params} fullWidth required />}
slotProps={{
textField: {
error: false,
},
}}
sx={{ width: '100%' }}
openTo="year"
views={['year', 'month', 'day']}
minDate={new Date("1930-01-01")}
maxDate={new Date()} // Set maximum date as current date
/>
</FormControl>
<FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="Email"
    value={currentParticipant?.email || ''}
    onChange={(e) => setCurrentParticipant({ ...currentParticipant, email: e.target.value })}
    fullWidth
    required
    type='email'
  />
</FormControl>

<Autocomplete
id="country-select-demo"
options={Allcountries}
getOptionLabel={(option) => option.label} // Show country name as label
value={currentParticipant?.ResidenceCountry || null} // Bind selected country from currentParticipant
onChange={(event, newValue) => {
setCurrentParticipant({ ...currentParticipant, ResidenceCountry: newValue }); // Update currentParticipant's ResidenceCountry
}} // Handle change to update currentParticipant's country
renderOption={(props, option) => (
<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
<img
loading="lazy"
width="20"
srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
alt=""
/>
{option.label}
</Box>
)}
renderInput={(params) => (
<TextField
{...params}
variant="outlined"
label="Country of Residence"
placeholder="Country of Residence"
margin="normal"
/>
)}
isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
/>

<FormControl fullWidth margin="normal" variant="outlined">
  <TextField
    label="Budget"
    value={currentParticipant?.budget || ''}
    onChange={(e) => setCurrentParticipant({ ...currentParticipant, budget: e.target.value })}
    fullWidth
    type='number'
  />
</FormControl>
<FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>Highest Education Level</InputLabel>
    <Select
      label="Highest Education Level"
      value={currentParticipant?.highestEducationLevel || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, highestEducationLevel: e.target.value })}
      >
      <MenuItem value="High School">High School</MenuItem>
            <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
            <MenuItem value="Master's Degree">Master's Degree</MenuItem>
            <MenuItem value="Doctorate Degree">Doctorate Degree</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
    </Select>
  </FormControl>
  <FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>Preferred Study Level</InputLabel>
    <Select
      label="Preferred Study Level"
      value={currentParticipant?.preferredStudyLevel || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, preferredStudyLevel: e.target.value })}
      >
      <MenuItem value="School">School</MenuItem>
            <MenuItem value="undergraduate">Undergraduate</MenuItem>
            <MenuItem value="postgraduate">Postgraduate</MenuItem>
            <MenuItem value="Doctorate">Doctorate</MenuItem>
            <MenuItem value="English Language">English Language</MenuItem>
            <MenuItem value="Vocational">Vocational</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
    </Select>
  </FormControl>
  <FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>Grade</InputLabel>
    <Select
      label="Grade"
      value={currentParticipant?.grade || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, grade: e.target.value })}
      >
     <MenuItem value="Grade 10">Grade 10</MenuItem>
      <MenuItem value="Grade 11">Grade 11</MenuItem>
            <MenuItem value="Grade 12">Grade 12</MenuItem>
            <MenuItem value="Graduate">Graduate</MenuItem>
    </Select>
  </FormControl>
  <FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>English Certificate</InputLabel>
    <Select
      label="English Certificate"
      value={currentParticipant?.englishLevel || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, englishLevel: e.target.value })}
      >
            <MenuItem value="IELTS">IELTS</MenuItem>
            <MenuItem value="TOEFL">TOEFL</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
    </Select>
  </FormControl>
  <Autocomplete
multiple // Allows multiple selections
id="desired-field-select"
fullWidth
options={desiredFields} // The list of desired fields
getOptionLabel={(option) => option.label} // Display field label
value={currentParticipant?.studyFields || []} // Bind selected fields from currentParticipant
onChange={(event, newValue) => {
setCurrentParticipant({
...currentParticipant,
studyFields: newValue, // Update currentParticipant with the selected fields
});
}}
renderOption={(props, option) => (
<li {...props}>
{option.label}
</li>
)}
renderInput={(params) => (
<TextField
{...params}
label="Desired Fields"
placeholder="Select desired fields"
margin="normal"
/>
)}
// Display selected values as chips
renderTags={(value, getTagProps) =>
value.map((option, index) => (
<Chip
label={option.label}
{...getTagProps({ index })}
/>
))
}
isOptionEqualToValue={(option, value) => option.label === value.label} // Match by label
/>

<FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>Financial Source</InputLabel>
    <Select
      label="Financial Source"
      value={currentParticipant?.financialSource || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
      >
      <MenuItem value="Self-Funded">Self-Funded</MenuItem>
            <MenuItem value="Parents">Parents</MenuItem>
            <MenuItem value="Scholarship">Scholarship</MenuItem>
            <MenuItem value="Bank Loan">Bank Loan</MenuItem>
            <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
    </Select>
  </FormControl>
  <Grid container spacing={2}>
<Grid item xs={12} sm={6}>
<FormControl variant="outlined" margin="normal" fullWidth>
<DatePicker
views={['year']}
label="Desired Year"
// Initialize the value to the year if it exists or null
value={currentParticipant?.intakeYear ? new Date(currentParticipant.intakeYear, 0) : null}
onChange={(newDate) => {
// Ensure we only save the year
const year = newDate ? newDate.getFullYear() : null; // Extract year or set to null
setCurrentParticipant({ ...currentParticipant, intakeYear: year });
}}
slotProps={{
textField: {
error: false,
},
}}
sx={{ width: '100%' }}
minDate={new Date(new Date().getFullYear(), 0)} // Ensure only future years are selectable
renderInput={(params) => <TextField {...params} fullWidth />}
/>
</FormControl>
</Grid>

{/* Month Picker */}
<Grid item xs={12} sm={6}>
<FormControl variant="outlined" margin="normal" fullWidth>
<DatePicker
views={['month']}
label="Desired Month"
// Initialize the value to a date object where the month is set (0-based for JS Date)
value={currentParticipant?.intakeMonth ? new Date(2024, currentParticipant.intakeMonth - 1) : null}
slotProps={{
textField: {
error: false,
},
}}
onChange={(newDate) => {
// Extract the selected month and add 1 to convert from 0-11 to 1-12
const month = newDate ? newDate.getMonth() + 1 : null;
setCurrentParticipant({ ...currentParticipant, intakeMonth: month });
}}
renderInput={(params) => <TextField {...params} fullWidth />}
/>
</FormControl>

</Grid>

</Grid>
  <Autocomplete
    multiple
    id="country-select-demo"
    options={countries}
    getOptionLabel={(option) => option.label} // Show country name as label
    value={currentParticipant?.preferredDestinations || []} // Bind selected countries from currentParticipant
    onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
    renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
     <img
       loading="lazy"
       width="20"
       srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
       src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
       alt=""
     />
     {option.label} 
   </Box>                
  )}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label="Preferred Destinations"
        placeholder="Preferred Destinations"
        margin="normal"
      />
    )}
    renderTags={(value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          label={option.label}
          {...getTagProps({ index })}
          avatar={<span>{getFlagEmoji(option.code)}</span>} // Show flag in chip
        />
      ))
    }
    isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
  />
  
 
  <FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>Status</InputLabel>
    <Select
      label="Status"
      value={currentParticipant?.status || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, status: e.target.value })}
      >
     <MenuItem value="Open">Open</MenuItem>
      <MenuItem value="Cold">Cold</MenuItem>
            <MenuItem value="Warm">Warm</MenuItem>
            <MenuItem value="Hot">Hot</MenuItem>

    </Select>
  </FormControl>
  <FormControl variant="outlined" margin="normal" fullWidth>
    <InputLabel>Action</InputLabel>
    <Select
      label="Action"
      value={currentParticipant?.action || ''}
      onChange={(e) => setCurrentParticipant({ ...currentParticipant, action: e.target.value })}
      >
      <MenuItem value="0">Normal</MenuItem>
            <MenuItem value="1">Call Back</MenuItem>
            <MenuItem value="2">Counseling</MenuItem>
            <MenuItem value="3">Follow Up</MenuItem>
            <MenuItem value="4">Option Sharing</MenuItem>
    </Select>
  </FormControl>
  <FormControl variant="outlined" margin="normal" fullWidth>
  <TextField
    label="Reminder Time"
    type="datetime-local"
    value={currentParticipant?.timeAction || ""} // Default to an empty string
    onChange={(e) => setCurrentParticipant({ 
      ...currentParticipant, 
      timeAction: e.target.value 
    })}
    fullWidth
    sx={{ mb: 3 }}
    InputLabelProps={{
      shrink: true, // Ensures the label doesn't overlap with the input
    }}
  />
</FormControl>


  <Grid container spacing={2} sx={{ mt: 2 }}>
  <Grid item xs={12} sm={12}>
    <input
      accept="image/*,application/pdf" // Accepts images and PDFs
      style={{ display: 'none' }}
      id="upload-photo"
      type="file"
      onChange={handlePhotoUpload}
    />
    <label htmlFor="upload-photo">
      <Button
        startIcon={<CloudUploadIcon />}
        variant="outlined"
        component="span"
        fullWidth
        sx={{
          mt: 2,
          py: 1.5,
          fontWeight: 'bold',
          borderColor: 'primary.main',
          color: 'primary.main',
        }}
      >
        Upload Extra Document
      </Button>
    </label>
    {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
  </Grid>
  <Grid item xs={12}>
<Typography
variant="body2"
sx={{
  mt: 2,
  color: 'text.secondary',
  fontWeight: 'medium',
  lineHeight: 1.5,
  textAlign: 'center',
}}
>
📄 <strong>Important:</strong> Please note that you can only upload <strong>one document</strong>. 
If you have multiple files, kindly <strong>merge them</strong> into a single PDF before uploading. 
If you've previously uploaded an extra document, please <strong>download the file, merge them all </strong> 
 and re-upload merged file here.
</Typography>
</Grid>
</Grid>
  <Button type="submit"   variant="contained"  sx={{
  mt: 3,
  py: 1.5,
  fontWeight: 'bold',
  backgroundColor: '#0033A1 !important',
  '&:hover': {
    backgroundColor: '#002080 !important',
  },
}}>
    Save Changes
  </Button>
</form>
</LocalizationProvider>
</ThemeProvider>

</Box>
</Modal>



  );
};

export default EditParticipantModal;
