import React, { useState, useEffect } from 'react';
import {
  Box, Button, Modal, TextField, Typography, FormControl, Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle, Slide,Grid
} from '@mui/material';
import axios from 'axios';
import dayjs from 'dayjs';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import LinearProgress from '@mui/material/LinearProgress';
import Autocomplete from '@mui/material/Autocomplete';
import { InputLabel, Select, MenuItem } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#0033A1"
    },
    secondary: {
      main: "#0033A1"
    }
  }
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight: '90%',
  overflowY: 'auto',
};

const dialogStyle = {
  zIndex: 1400, // Ensure the dialog is above the modal
};

const AddNewsModal = ({ open, handleClose, fetchNews, setAlertOpen, setMessage, setSeverity }) => {

  const [error, setError] = useState('');
  const [countries, setCountries] = useState([]);

  const [openDialog, setOpenDialog] = useState(false);
  const [progress, setProgress] = useState(false);

  const [formData, setFormData] = useState({
    name:"",
    email: '',
    mobile: '',
    country:"",
    contactPerson:"",
    title:"",
    type:"",
    comment:"",
    link:"",
    status:"not_done",
    photo:null
  });


  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setCountries(response.data[5].countries);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {

      
      setProgress(true);
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

  if (!token) {
    // Handle missing token (e.g., redirect to login page)
    console.error('No token found');
    return;
  }


      const response = await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/addservice', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });

      if (response.status === 201) {
        fetchNews();
        handleCloseModal();
        setMessage("Provider created successfully");
        setSeverity("success");
        setAlertOpen(true);
      } else {
        handleCloseModal();
        setMessage("Error adding Provider");
        setSeverity("error");
        setAlertOpen(true);
      }
    } catch (error) {
      handleCloseModal();
      setMessage("Error adding Provider");
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handlePhotoUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
      if (!validFileTypes.includes(file.type)) {
        setMessage("Please upload a valid file (JPEG, PNG, or PDF)");
        setSeverity("error");
        setAlertOpen(true);
        setFormData({ ...formData, photo: null })
        return;
      }
  
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
        setSeverity("error");
        setAlertOpen(true);
        setFormData({ ...formData, photo: null })
        return;
      }
  
      // Check if it's a PDF
      if (file.type === 'application/pdf') {
        const extension = file.name.split('.').pop();
        const newName = `edugate_contract_${Date.now()}.${extension}`;
        const renamedFile = new File([file], newName, { type: file.type });
        setFormData({ ...formData, photo: renamedFile })

      } else {
        // If it's an image, perform image-specific validation
        const reader = new FileReader();
        reader.onload = (e) => {
          const img = new Image();
          img.onload = () => {
            // File is a valid image
            const extension = file.name.split('.').pop();
            const newName = `edugate_contract_${Date.now()}.${extension}`;
            const renamedFile = new File([file], newName, { type: file.type });
            setFormData({ ...formData, photo: renamedFile })

            
          };
          img.onerror = () => {
            setMessage("The uploaded file is not a valid image or is corrupted");
            setSeverity("error");
            setAlertOpen(true);
            setFormData({ ...formData, photo: null })

          };
          img.src = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleCloseModal = () => {
   
    setError("");
   setFormData({
        name:"",
        email: '',
        mobile: '',
        country:"",
        contactPerson:"",
        title:"",
        type:"",
        comment:"",
        link:"",
        status:"not_done",
        photo:null
      });
    handleClose();
  };

  return (
    <div>
            <ThemeProvider theme={theme}>

      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        sx={dialogStyle}
      >
        <DialogTitle>{"Invalid Image Format"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Please upload a valid  file (JPEG, PNG, PDF).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Modal
  open={open}
  onClose={handleCloseModal}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is below the dialog
>
  <Box
    sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90%',
        maxWidth: 600,
        bgcolor: 'background.paper',
        borderRadius: 2,
        boxShadow: 24,
        p: 4,
        overflowY: 'auto',
        maxHeight: '85%',
    }}
  >
    <Typography
      id="modal-title"
      variant="h5"
      component="h2"
      sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
    >
      Add New Provider
    </Typography>

    <form encType="multipart/form-data" onSubmit={handleSubmit}>
    <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Name"
                value={formData.name}
                onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Website"
                value={formData.link}
                onChange={(e) => setFormData({ ...formData, link: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Email"
                value={formData.email}
                onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                fullWidth
                required
                type='email'
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Mobile"
                value={formData.mobile}
                onChange={(e) => setFormData({ ...formData, mobile: e.target.value })}
                fullWidth
                required
                type='number'
              />
            </FormControl>
            <Autocomplete
  id="country-select-demo"
  fullWidth
  options={countries}
  autoHighlight
  getOptionLabel={(option) => option.label}
  value={countries.find((c) => c.label === formData.country) || null}
  onChange={(event, newValue) => {
    setFormData({
      ...formData,
      country: newValue ? newValue.label : null, // Set to null if no selection
    });
  }}
  inputValue={formData.country || ''}
  onInputChange={(event, newInputValue) => {
    setFormData({
      ...formData,
      country: newInputValue,
    });
  }}
  renderOption={(props, option) => (
      <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            alt=""
          />
          {option.label} 
        </Box>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Choose a country"
      inputProps={{
        ...params.inputProps,
        autoComplete: 'new-password',
      }}
      name="country"
      required
      sx={{ mt: 2 }}

    />
  )}
/>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Contact Person"
                value={formData.contactPerson}
                onChange={(e) => setFormData({ ...formData, contactPerson: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Title"
                value={formData.title}
                onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                fullWidth
                required
              />
            </FormControl>
      
            <FormControl fullWidth margin="normal" variant="outlined">
              <TextField
                label="Comment"
                value={formData.comment}
                onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
                fullWidth
                multiline
                rows={4}
              />
            </FormControl>
            <FormControl required variant="outlined" fullWidth margin="normal">
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={formData?.type || ''}
                onChange={(e) => setFormData({ ...formData, type: e.target.value })}
                >
                <MenuItem value="Aggregator">Aggregator</MenuItem>
                      <MenuItem value="University">University</MenuItem>
                      <MenuItem value="Student Services">Student Services</MenuItem>

              </Select>
            </FormControl>
            <FormControl variant="outlined" fullWidth margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={formData?.status || ''}
                onChange={(e) => setFormData({ ...formData, status: e.target.value })}
                >
                <MenuItem value="done">Done</MenuItem>
                      <MenuItem value="not_done">Not Done</MenuItem>
              </Select>
            </FormControl>

            <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12}>
      
      <input
      accept="image/*,application/pdf" // Accepts images and PDFs
      style={{ display: 'none' }}
        id="upload-photo"
        type="file"
        onChange={handlePhotoUpload}
      />
      <label htmlFor="upload-photo">
        <Button
          startIcon={<CloudUploadIcon />}
          variant="outlined"
          component="span"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            fontWeight: 'bold',
            borderColor: 'primary.main',
            color: 'primary.main',
          }}
        >
          Upload Contract
        </Button>
      </label>
      {formData.photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{formData.photo.name}</Typography>}
      </Grid>
          </Grid>
      {error && (
        <Typography color="error" variant="body2" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        fullWidth
        sx={{
          mt: 3,
          py: 1.5,
          fontWeight: 'bold',
          backgroundColor: '#0033A1 !important',
          '&:hover': {
            backgroundColor: '#002080 !important',
          },
        }}      >
        Add Service Provider
      </Button>

      {progress && (
        <Box sx={{ width: '100%', mt: 2 }}>
          <LinearProgress />
        </Box>
      )}
    </form>
  </Box>
</Modal>
</ThemeProvider>
    </div>
  );
};

export default AddNewsModal;
