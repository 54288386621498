import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Modal,
  TextField,
  LinearProgress,
  FormControl,
  Typography,
  Alert,
  Collapse,
  Grid,List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Select, MenuItem,InputLabel,Divider
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import {createTheme, ThemeProvider } from '@mui/material/styles';
import moment from 'moment'; // For formatting the date and time
import { Autocomplete, Chip } from '@mui/material';
import countries from './countries'; // Import your country list
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { IconButton, Tooltip } from '@mui/material';
import HistoryToggleOffIcon from '@mui/icons-material/HistoryToggleOff';
import NotesIcon from '@mui/icons-material/Notes';
import { Comment, Send, Close } from '@mui/icons-material';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditParticipantModal from './EditParticipantModal'; // Adjust the path as needed
import CommentModal from './Comment';
import StudentInfoModal from "./StudentInfoModal";
import ParticipantLogsModal from './ParticipantLogsModal';

const TransferredParticipants = () => {
  const [selectedBefore, setSelectedBefore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [stageFilter, setStageFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [photo, setPhoto] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [commentOpen, setCommentOpen] = useState(false);
  const [desiredFields, setDesiredFields] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [Allcountries, setAllCountries] = useState([]);
  
  const [openLogs, setOpenLogs] = useState(false);
  const [archiveOpen, setArchiveOpen] = useState(false);

  // Function to handle opening the modal

  const handleOpenArchive = (participant) => {
    setCurrentParticipant(participant);
    setArchiveOpen(true);
  };
  const handleCloseArchive = () => {
    setArchiveOpen(false);
    setCurrentParticipant(null);

  };

  const handleOpenLogs = (participant) => {
    setCurrentParticipant(participant);
    setOpenLogs(true);
  };



  // Function to handle closing the modal

  const handleCloseLogs = () => {
    setCurrentParticipant(null);
    setOpenLogs(false);
  };


  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setAllCountries(response.data[5].countries);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);

  const theme = createTheme({
    palette: {
      primary: {
        main: "#0033A1"
      },
      secondary: {
        main: "#0033A1"
      }
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });
  // Function to get country flag based on code


  const fetchTransferredParticipants = async () => {
    const username = sessionStorage.getItem('username'); // Get the username from session storage
    const levelnum = sessionStorage.getItem('levelnum');
    
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      let response;//
      if (levelnum === '1'|| levelnum === '3') {
        // Fetch all transferred participants if levelnum is '1'
        response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/allarchived`,{
          headers: { 
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
          }
        });
      } else {
        // Otherwise, fetch transferred participants specific to the username
        response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/archived`, {
          params: { username },
          headers: { 
            Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header
          }
        });
      }
  
      // Ensure response data exists before reversing and setting state
      if (response?.data) {
        const filteredData = response.data.filter((item) => item.archived === "1");

// Optionally reverse the filtered data if needed
     setSelectedBefore(filteredData.reverse()); // Update state with filtered participants
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false); // End loading state after fetch attempt
    }
  };
  

  useEffect(() => {
    fetchTransferredParticipants();
  }, []);


  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setDesiredFields(response.data[3].desiredFields);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);


  const handleCommentOpen = (participant) => {
    setCurrentParticipant(participant);
    setCommentOpen(true);
  };

  const handleCommentClose = () => {
    setCurrentParticipant(null);
    setCommentOpen(false);
    setNewComment('');
  };
  const handleEditOpen = (participant) => {
    setCurrentParticipant(participant);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentParticipant(null);
    setEditOpen(false);
    setPhoto(null)
  };

  const handleEditSubmit2 = async (e) => {
    if (e) {
        e.preventDefault();  // Only call preventDefault if the event exists
    }
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
        const { _id,username, ...participantData } = currentParticipant; // Include username if present
        console.log("Call handleEditSubmit...");

        participantData.photo = photo; // Add the photo field to the new object
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`,{
        username, // Ensure username is included
            ...participantData,
        },
        {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

            },
          }
    );

       
        setMessage('Participant updated successfully');
        setSeverity('success');
        setAlertOpen(true);
        fetchTransferredParticipants(); // Refresh the data
    } catch (error) {
        setMessage('Error updating participant: ' + error.message);
        setSeverity('error');
        setAlertOpen(true);
    }
    handleEditClose();
};
const  handleCommentEdit = async (comment) => {
  const updatedComments = currentParticipant.comment.map((existingComment) =>
    existingComment.dateTime === comment.dateTime
      ? { ...existingComment, text: newComment } // Update the text
      : existingComment
  );
  
  // Update the state and call the backend to save changes
  const updatedParticipantData = { ...currentParticipant, comment: updatedComments };
  setCurrentParticipant(updatedParticipantData);
  setNewComment('');

  const { _id, ...participantData } = updatedParticipantData; // Include username if present
  try {
    const token = localStorage.getItem('token');  // Retrieve the token from localStorage

    if (!token) {
      // Handle missing token (e.g., redirect to login page)
      console.error('No token found');
      return;
    }
      // Send the entire participant's data without the _id to the backend
      await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`, {
          ...participantData,
          photo:null
                  },{
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

                    },
                  });

      // Optionally, you can show a success message or reload the data from the backend
      fetchTransferredParticipants(); // Refresh the data
      setMessage('Participant updated successfully');
      setSeverity('success');
      setAlertOpen(true);
    } catch (error) {
      setMessage('Error updating participant: ' + error.message);
      setSeverity('error');
      setAlertOpen(true);
    }
};

// Modify handleEditSubmit to accept the participant data as a parameter
const handleEditSubmit = async (updatedParticipant) => {
  try {
      const { _id, username, ...participantData } = updatedParticipant; // Use updatedParticipant instead of state

      participantData.photo = photo; // Add the photo field to the new object

      // Send updated data to the server
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.put(
          `https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`,
          {
              username, // Ensure username is included
              ...participantData,
          },
          {
              headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

              },
          }
      );

      // Success message
      setMessage('Participant updated successfully');
      setSeverity('success');
      setAlertOpen(true);
      fetchTransferredParticipants(); // Refresh the data
  } catch (error) {
      setMessage('Error updating participant: ' + error.message);
      setSeverity('error');
      setAlertOpen(true);
  }
  handleEditClose();
};


const handlePhotoUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      setMessage("Please upload a valid file (JPEG, PNG, or PDF)");
      setSeverity("error");
      setAlertOpen(true);
      setPhoto(null);
      return;
    }

    const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
      setSeverity("error");
      setAlertOpen(true);
      setPhoto(null);
      return;
    }

    // Check if it's a PDF
    if (file.type === 'application/pdf') {
      const extension = file.name.split('.').pop();
      const newName = `edugate_student_document_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
      setPhoto(renamedFile);
    } else {
      // If it's an image, perform image-specific validation
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is a valid image
          const extension = file.name.split('.').pop();
          const newName = `edugate_contract_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error");
          setAlertOpen(true);
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
};
const parseDate = (dateString) => {
  if (!dateString) return null; // Return null for falsy inputs
  if (dateString instanceof Date) return dateString;
  const parsedDate = new Date(dateString);
  return isNaN(parsedDate.getTime()) ? null : parsedDate; // Ensure valid date
};


  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleStageFilterChange = (event) => {
    setStageFilter(event.target.value);
  };

  

  const handleUserFilterChange = (event) => {
    setUserFilter(event.target.value);
  };
  const filteredParticipants = selectedBefore.filter((participant) => {
    // Check both statusFilter and userFilter
    const statusMatches = !statusFilter || participant.status === statusFilter;
    const userMatches = !userFilter || participant.username.includes(userFilter);
    const stageMatches = !stageFilter || participant.stage.includes(stageFilter);

    return statusMatches && userMatches &&stageMatches ;
  });
  

  const columns = [
    { field: 'f_name', headerName: 'First Name', width: 150 },
    { field: 'l_name', headerName: 'Last Name', width: 150 },
  //  { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'country', headerName: 'Nationality', width: 150 },
    // { field: 'birthdate', headerName: 'Birthdate', width: 150 },
    { field: 'visitor_type', headerName: 'Highest Level of Education', width: 150 },
    { field: 'school_name', headerName: 'School Name', width: 150 },
    // { field: 'hear_aboutus_at', headerName: 'Hear about us at', width: 150 },
    { field: 'dateTime', headerName: 'Create Date', width: 150 },
    // { field: 'username', headerName: 'Assigned Employee', width: 150 },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        let icon;
        let color;
    
        switch (params.value) {
          case 'Hot':
            icon = <WhatshotIcon style={{ color: 'orange' }} />;
            color = 'green'; // Or any color you'd like for hot status
            break;
          case 'Cold':
            icon = <AcUnitIcon style={{ color: 'blue' }} />;
            color = 'blue'; // Change to your desired color for cold
            break;
          case 'Warm':
            icon = <HourglassBottomIcon style={{ color: 'gold' }} />;
            color = 'orange'; // Change to your desired color for warm
            break;
          default:
            icon = <LockOpenIcon/>; // No icon for unknown status
            color = 'gray'; // Default color
            break;
        }
        return (
          <span style={{ color }}>
            {icon} {params.value}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 250,
      renderCell: (params) => (
        <Box>
          <Tooltip title="Open Notes" arrow>
            <IconButton onClick={() => handleCommentOpen(params.row)}>
              <NotesIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit Student Info" arrow>
            <IconButton onClick={() => handleEditOpen(params.row)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Open Logs" arrow>
          <IconButton onClick={() => handleOpenLogs(params.row)}>

              <HistoryToggleOffIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Unarchive Student" arrow>
          <IconButton onClick={() => handleOpenArchive(params.row)}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    },
    
  ];
  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;  // Ensure comment is not empty

    // Create a new comment object with timestamp
    const commentWithTimestamp = {
        text: newComment,
        dateTime: moment().format('MMMM Do YYYY, h:mm:ss a'), // Current timestamp
    };

    // Append the new comment to the participant's existing comments
    const updatedComments = [...(currentParticipant.comment || []), commentWithTimestamp];

    // Create a new object with the updated comments
    const updatedParticipantData = {
        ...currentParticipant,
        comment: updatedComments,
    };

    // Update the current participant's comments in state
    setCurrentParticipant(updatedParticipantData);
    setNewComment('');

    const { _id, ...participantData } = updatedParticipantData; // Include username if present


    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
        // Send the entire participant's data without the _id to the backend
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`, {
            ...participantData,
            photo:null
                    },{
                      headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

                      },
                    });

        // Optionally, you can show a success message or reload the data from the backend
        fetchTransferredParticipants(); // Refresh the data
        setMessage('Participant updated successfully');
        setSeverity('success');
        setAlertOpen(true);
      } catch (error) {
        setMessage('Error updating participant: ' + error.message);
        setSeverity('error');
        setAlertOpen(true);    }

    // Close the modal after submitting the comment
  };
  const handleDestinationsChange = (event, value) => {
    setCurrentParticipant((prev) => ({
      ...prev,
      preferredDestinations: value, // Update preferred destinations with the new selected values
    }));
  }
  const handleConfirmArchive = () => {
    // Set archived to "1"
    const newpart={
      ...currentParticipant,
      archived:"0"
    }
    // Call the editUpdateSubmit with updated data
    handleEditSubmit(newpart);

    // Close the dialog after action
    handleCloseArchive();  // Corrected this line to invoke the function
  };


  return (
<ThemeProvider theme={theme}>
      <div>     
         <Collapse in={alertOpen}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>

      <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>

      <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                label="Filter by Status"
                value={statusFilter}
                onChange={handleStatusFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
                <MenuItem value="Cold">Cold</MenuItem>
                      <MenuItem value="Warm">Warm</MenuItem>
                      <MenuItem value="Hot">Hot</MenuItem>
              </Select>
            </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>

      <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Stage</InputLabel>
              <Select
                label="Filter by Stage"
                value={stageFilter}
                onChange={handleStageFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Not Qualified">Not Qualified</MenuItem>
              <MenuItem value="Qualified">Qualified</MenuItem>                 
              <MenuItem value="Applied">Applied</MenuItem>
              <MenuItem value="Finalized">Finalized</MenuItem>
              <MenuItem value="Visa">Visa</MenuItem>
              <MenuItem value="Commission Tracking">Commission Tracking</MenuItem>
              </Select>
            </FormControl>
      </Grid>

      
      {(sessionStorage.getItem('levelnum') === '1' || sessionStorage.getItem('levelnum') === '3') && (
  <Grid item xs={12} sm={6} md={3}>
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Filter by User</InputLabel>
      <Select
        label="Filter by User"
        value={userFilter}
        onChange={handleUserFilterChange}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="studyabroad@rana">Rana Abdelghafour</MenuItem>
        <MenuItem value="studyabroad@alaa">Alaa El-Saeed</MenuItem>
      </Select>
    </FormControl>
  </Grid>
)}
        </Grid>
      </Box>
      

      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <DataGrid
          rows={filteredParticipants}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          autoHeight
          disableRowSelectionOnClick
        />
      )}

<EditParticipantModal
        open={editOpen}
        onClose={handleEditClose}
        currentParticipant={currentParticipant}
        setCurrentParticipant={setCurrentParticipant}
        handleEditSubmit={handleEditSubmit2}
        handlePhotoUpload={handlePhotoUpload}
        photo={photo}
        Allcountries={Allcountries}
        desiredFields={desiredFields}
        countries={countries}
        handleDestinationsChange={handleDestinationsChange}
        parseDate={parseDate}
      />
      <StudentInfoModal
        open={commentOpen}
        onClose={() => setCommentOpen(false)}
        currentParticipant={currentParticipant}
      />
      <CommentModal
        open={commentOpen}
        onClose={() => setCommentOpen(false)}
        currentParticipant={currentParticipant}
        newComment={newComment}
        setNewComment={setNewComment}
        handleCommentSubmit={handleCommentSubmit}
        handleCommentEdit= {handleCommentEdit}

      />
    </div>

    <ParticipantLogsModal
        openLogs={openLogs}
        handleCloseLogs={handleCloseLogs}
        currentParticipant={currentParticipant}
      />
 <Dialog
        open={archiveOpen}
        onClose={handleCloseArchive}
        aria-labelledby="archive-dialog-title"
        aria-describedby="archive-dialog-description"
      >
        <DialogTitle id="archive-dialog-title">
          {"Confirm Unarchive"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="archive-dialog-description">
            Are you sure you want to Unarchive this Student?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchive} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmArchive} color="secondary" autoFocus>
            Yes, Unarchive
          </Button>
        </DialogActions>
      </Dialog>


    </ThemeProvider>

  );
};

export default TransferredParticipants;
