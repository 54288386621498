import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  Snackbar,
  Alert,
  Modal,
} from "@mui/material";
import dayjs from "dayjs";

const RemainderComponent = ({ student, open,updateStudent,setCurrentParticipant ,handleClose }) => {
  const [remainderTime, setRemainderTime] = useState(() => {
    const now = dayjs();
    return student?.remainderTime
      ? dayjs(student?.remainderTime).format("YYYY-MM-DDTHH:mm")
      : now.add(1, "hour").format("YYYY-MM-DDTHH:mm");
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSetRemainder = async () => {
    setLoading(true);
    try {
      // const updatedStudent = { ...student, remainderTime };

      setCurrentParticipant((prevState) => {
        const updatedParticipant = { ...prevState, remainderTime};

        // Call the submit function here, which will now have the updated participant data
        updateStudent(updatedParticipant);

        return updatedParticipant;
    });
      // Simulate API call: await updateStudent(updatedStudent);
      setSnackbar({
        open: true,
        message: `Remainder set for ${student?.f_name} at ${remainderTime}`,
        severity: "success",
      });
      handleClose()
    } catch (error) {
      console.error("Failed to set remainder:", error);
      setSnackbar({
        open: true,
        message: "Failed to set remainder.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleResetRemainder = async () => {
    setLoading(true);
    try {
      const updatedStudent = { ...student, remainderTime: null };
      // Simulate API call: await updateStudent(updatedStudent);
      
const remainderTime=""
      setCurrentParticipant((prevState) => {
        const updatedParticipant = { ...prevState, remainderTime};

        // Call the submit function here, which will now have the updated participant data
        updateStudent(updatedParticipant);

        return updatedParticipant;
    });

    
      setRemainderTime(dayjs().add(1, "hour").format("YYYY-MM-DDTHH:mm"));
      setSnackbar({
        open: true,
        message: `Remainder reset for ${student?.f_name}`,
        severity: "success",
      });
      handleClose()

    } catch (error) {
      console.error("Failed to reset remainder:", error);
      setSnackbar({
        open: true,
        message: "Failed to reset remainder.",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleTimeChange = (event) => {
    setRemainderTime(event.target.value);
  };

  const handleCloseSnackbar = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 4,
          borderRadius: 3,
          maxWidth: 500,
          mx: "auto",
          mt: "10%",
          bgcolor: "background.paper",
          boxShadow: 24,
        }}
      >
        <Typography variant="h5" gutterBottom>
          Set Reminder for {student?.f_name || "Student"}
        </Typography>

        <TextField
          label="Reminder Time"
          type="datetime-local"
          value={remainderTime}
          onChange={handleTimeChange}
          fullWidth
          sx={{ mb: 3 }}
          InputProps={{
            inputProps: { min: dayjs().format("YYYY-MM-DDTHH:mm") },
          }}
        />

        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSetRemainder}
            disabled={loading}
            fullWidth
          >
            {loading ? "Saving..." : "Save Reminder"}
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleResetRemainder}
            disabled={loading}
            fullWidth
          >
            {loading ? "Removing..." : "Remove Remainder"}
          </Button>
        </Box>

        <Button
          variant="text"
          onClick={handleClose}
          sx={{ mt: 2, width: "100%" }}
        >
          Cancel
        </Button>

        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default RemainderComponent;
