import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Table, TableBody,FormControl, TableCell, InputLabel,Select,MenuItem,TableContainer, TableHead, TableRow, Paper, Button, Box, IconButton, Collapse, Modal, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide,Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PeopleIcon from '@mui/icons-material/People';
import AddEventModal from './AddEventModal';
import * as XLSX from 'xlsx';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Photo } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import EditEventModal from './EditEventModal';  // Adjust the path accordingly

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const formatDate = (date) => dayjs(date).format('YYYY-MM-DD');


const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [error, setError] = useState('');
  const [Fairphoto, setFairPhoto] = useState(null);
  const [Schedulephoto, setSchedulePhoto] = useState(null);
  const [initialEvent, setInitialEvent] = useState(null); // New state to hold initial event data
  const [progress, setProgress] = useState(false);

  

  useEffect(() => {
    fetchEvents();
  }, []);
 

  const fetchEvents = async () => {
    setProgress(true);
    try {
      const response = await axios.get('https://edugate-backend-b5ad4c02888d.herokuapp.com/api/getevent');
  
      // Filter out events where deleted equals 0 and sort by nearest start_date
      const filteredEvents= response.data.filter(event => event.deleted === '0')

      const filteredAndSortedEvents = filteredEvents
        .sort((a, b) => {
          const dateA = new Date(a.start_date);
          const dateB = new Date(b.start_date);
          return dateA - dateB; // Ascending order
        });
  
      setEvents(filteredAndSortedEvents);
    } catch (error) {
      setMessage("Error fetching events: " + error.message);
      setSeverity("error");
      setAlertOpen(true);
    }
    setProgress(false);
  };
  
  
  
  
  const getStatus = (endDate, deleted) => {
    const today = dayjs();
    const end = dayjs(endDate);
    if (deleted === "1" || end.isBefore(today)) {
      return { text: 'Finished', isFinished: true };
    }
    return { text: `${end.diff(today, 'day')} days left`, isFinished: false };
  };

  const getStatusStyle = (status) => {
    if (status.isFinished) {
      return { color: 'green' };
    }
    return { color: 'orange' };
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleEditOpen = (event) => {
    setCurrentEvent(event);
    setInitialEvent(event);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentEvent(null);
    setInitialEvent(null);
    setEditOpen(false);
  };

  const handleDelete = async () => {
    setProgress(true);
    try {
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

      if (!token) {
        // Handle missing token (e.g., redirect to login page)
        console.error('No token found');
        return;
      }
      await axios.delete(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/deleteevent/${eventToDelete}`,{
        headers: {
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });
      fetchEvents();
      handleCloseDeleteDialog();
      setMessage("Event Deleted Successfully");
      setSeverity("success")
      setAlertOpen(true);
    } catch (error) {
      setMessage("Error deleting event" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
    setProgress(false);
  };

  const handleOpenDeleteDialog = (eventId) => {
    setEventToDelete(eventId);
    setDeleteDialogOpen(true);
  };

  const handleCloseDeleteDialog = () => {
    setEventToDelete(null);
    setDeleteDialogOpen(false);
  };
  
  const handlePhotoScheduleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)")
        setSchedulePhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage('File size exceeds the limit of 2MB. Please choose a smaller file.');
        setSchedulePhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_event_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setSchedulePhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage('The uploaded file is not a valid image or is corrupted');
          setSchedulePhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handlePhotoFairUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        setMessage("Please upload a valid image file (JPEG, PNG)")
        setFairPhoto(null);
        return;
      }
      const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
      if (file.size > maxSizeBytes) {
        setMessage('File size exceeds the limit of 2MB. Please choose a smaller file.');
        setFairPhoto(null);
        return;
      }

      // Read the file to check if it's a valid image
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is valid
          const extension = file.name.split('.').pop();
          const newName = `edugate_event_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setFairPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage('The uploaded file is not a valid image or is corrupted');
          setFairPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleEditSubmit = async (updatedEvent) => {

    if (dayjs(updatedEvent.end_date).isBefore(dayjs(updatedEvent.start_date))) {
      setError('End date cannot be before start date');
      return;
    }
    const formattedStartTime = dayjs(`2020-01-01 ${updatedEvent.start_time}`, 'YYYY-MM-DD HH:mm');
    const formattedEndTime = dayjs(`2020-01-01 ${updatedEvent.end_time}`, 'YYYY-MM-DD HH:mm');

    if (formattedEndTime.isBefore(formattedStartTime)) {
      setError('End time cannot be before start time');
      return;
    }
    const hasChanged = Object.keys(initialEvent).some(
      key => initialEvent[key] !== currentEvent[key]
    );
    if (hasChanged || Schedulephoto || Fairphoto ) {
      setProgress(true);

    try {
      const newUpdateevent= {
        ...updatedEvent,
        fair_schedule: Schedulephoto,
        fair_map: Fairphoto,
      }
      const token = localStorage.getItem('token');  // Retrieve the token from localStorage

    if (!token) {
      // Handle missing token (e.g., redirect to login page)
      console.error('No token found');
      return;
    }
      await axios.put(`https://edugate-backend-b5ad4c02888d.herokuapp.com/api/updateevent/${currentEvent.id}`, newUpdateevent, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`  // Ensure token is passed correctly in the Authorization header

        }
      });

      fetchEvents();
      handleEditClose();
      setMessage("Event Updated Successfully");
      setSeverity("success")
      setFairPhoto(null);
      setSchedulePhoto(null); 
      setAlertOpen(true);
    } catch (error) {
      setMessage("Error updating event" + {error});
      setSeverity("error")
      setAlertOpen(true);
    }
    setError('');
    setProgress(false);
  }
  else{
    handleEditClose();
  }
  };



  return (
    <div>
      <Button sx={{mt:1,mb:1}} variant="contained" color="primary" onClick={handleOpen}>Create New Event</Button>
      <Box sx={{ width: '100%' }}>
      <Collapse in={alertOpen}>
        <Alert
        severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>
    </Box>
        {progress && (
    <Box sx={{ width: '100%' }}>
    <LinearProgress />
                    </Box>
                )}
    <TableContainer component={Paper} sx={{ maxHeight:"100vw",maxHeight: 600 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Start Date</TableCell>
              <TableCell>End Date</TableCell>
              <TableCell>Location Address</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Tools</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event) => (
              <React.Fragment key={event.id}>
                <TableRow>
                  <TableCell>{event.name}</TableCell>
                  <TableCell>{dayjs(event.start_date).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{dayjs(event.end_date || event.start_date).format('YYYY-MM-DD')}</TableCell>
                  <TableCell>{event.location_address}</TableCell>
                  <TableCell style={getStatusStyle(getStatus(event.end_date  || event.start_date, event.deleted))}>
                    {getStatus(event.end_date  || event.start_date, event.deleted).isFinished ? (
                      <>
                        {getStatus(event.end_date || event.start_date, event.deleted).text} <CheckCircleIcon style={{ color: 'green' }} />
                      </>
                    ) : (
                      <>
                        {getStatus(event.end_date || event.start_date, event.deleted).text} <HourglassBottomIcon style={{ color: 'orange' }} />
                      </>
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEditOpen(event)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleOpenDeleteDialog(event.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <AddEventModal open={open} handleClose={handleClose} fetchEvents={fetchEvents} setAlertOpen={setAlertOpen} setMessage={setMessage} setSeverity={setSeverity} />
      {currentEvent && (
       <EditEventModal
       editOpen={editOpen}
       handleEditClose={handleEditClose}
       currentEvent={currentEvent}
       setCurrentEvent={setCurrentEvent}
       handleEditSubmit={handleEditSubmit}
       handlePhotoFairUpload={handlePhotoFairUpload}
       handlePhotoScheduleUpload={handlePhotoScheduleUpload}
       Fairphoto={Fairphoto}
       Schedulephoto={Schedulephoto}
       error={error}
       progress={progress}
     />
      
      )}
      <Dialog
        open={deleteDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDeleteDialog}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete Event"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this event? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  color="secondary" onClick={handleCloseDeleteDialog}>Cancel</Button>
          <Button color="error" onClick={handleDelete} >Delete</Button>
        </DialogActions>
      </Dialog>

      
    </div>
  );
};

export default EventsPage;//
