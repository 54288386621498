import React, { useState } from "react";
import { Checkbox, FormControlLabel, Modal, Box, Typography, Link } from "@mui/material";

const TermsAndConditions = ({ onAccept }) => {
  const [showModal, setShowModal] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const handleAccept = (event) => {
    setIsAccepted(event.target.checked);
    onAccept(event.target.checked);
  };

  return (
    <>
      <FormControlLabel
        control={<Checkbox checked={isAccepted} onChange={handleAccept} />}
        label={
          <>
            I accept the <Link component="button" onClick={toggleModal} style={{ textDecoration: "underline" }}>Terms and Conditions</Link>
          </>
        }
      />

      <Modal open={showModal} onClose={toggleModal}>
        <Box
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "600px",
            backgroundColor: "#f9f9f9",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            padding: "25px",
            borderRadius: "10px",
            overflowY: "auto",
            maxHeight: "80vh",
            fontFamily: "'Roboto', sans-serif",
          }}
        >
          <Typography variant="h4" component="h1" style={{ marginBottom: "20px", textAlign: "center", color: "#333" }}>
            Terms and Conditions
          </Typography>

          <Typography variant="h6" component="h2" style={{ marginBottom: "15px", color: "#555" }}>
            Participation and Registration at EduGate Exhibitions
          </Typography>

          <Typography paragraph style={{ marginBottom: "15px", color: "#666" }}>
            By registering or participating in EduGate exhibitions through the EduGate Egypt website, attendees confirm their agreement to these Terms and Conditions.
          </Typography>

          <Typography variant="h6" component="h3" style={{ marginBottom: "10px", color: "#444" }}>
            Purpose of Registration
          </Typography>
          <Typography paragraph style={{ color: "#666" }}>
            Registration ensures seamless entry and personalized experiences for attendees. The personal data collected will be used to:
          </Typography>
          <ul style={{ paddingLeft: "20px", color: "#666" }}>
            <li>Generate a unique electronic entry pass (QR Code).</li>
            <li>Communicate event updates and details.</li>
            <li>Enhance engagement for students and institutions.</li>
          </ul>

          <Typography variant="h6" component="h3" style={{ marginBottom: "10px", color: "#444" }}>
            Registration Process
          </Typography>
          <Typography paragraph style={{ color: "#666" }}>
            Attendees must provide accurate and complete information during registration, including:
          </Typography>
          <ul style={{ paddingLeft: "20px", color: "#666" }}>
            <li>Full name.</li>
            <li>Contact details (email and phone number).</li>
            <li>Attendee type (e.g., student).</li>
          </ul>

          <Typography variant="h6" component="h3" style={{ marginBottom: "10px", color: "#444" }}>
            Use of QR Code and Data
          </Typography>
          <Typography paragraph style={{ color: "#666" }}>
            Each attendee will receive a unique QR Code upon registration. This code will be used for:
          </Typography>
          <ul style={{ paddingLeft: "20px", color: "#666" }}>
            <li>Identity verification.</li>
            <li>Sharing attendee data with participating institutions (with consent).</li>
          </ul>

          <Typography paragraph style={{ marginTop: "15px", fontStyle: "italic", textAlign: "center", color: "#555" }}>
            Thank you for registering and participating in EduGate exhibitions. We look forward to providing you with a seamless and enriching experience.
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default TermsAndConditions;

// Usage Example:
// <TermsAndConditions onAccept={(isAccepted) => console.log(isAccepted)} />
